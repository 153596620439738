var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.open,
            expression: "open",
          },
        ],
        staticClass:
          "asterix-modal max-h-full fixed z-100 w-full h-screen bottom-0 inset-x-0 px-4 py-4 sm:inset-0 flex items-center justify-center",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "bg-white rounded-lg sm:shadow-xl transform transition-all w-full sm:w-full z-20 overflow-hidden flex flex-col sm:flex-none max-h-full",
            class: _vm.modalClass,
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex w-full items-center justify-between px-4 py-4 sm:py-6 sm:px-6 border-b border-gray-300",
              },
              [
                _c(
                  "div",
                  { staticClass: "text-gray-700 font-bold text-xl" },
                  [
                    _vm._t("title", function () {
                      return [_vm._v(_vm._s(_vm.title))]
                    }),
                  ],
                  2
                ),
                _vm.closable
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "p-2 cursor-pointer text-gray-700 bg-gray-100 rounded",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("cancel")
                          },
                        },
                      },
                      [_c("close-svg", { staticClass: "w-4" })],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "section",
              { staticClass: "flex flex-col h-full overflow-hidden" },
              [
                _vm._t("container", function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full overflow-hidden flex flex-col h-full",
                      },
                      [
                        _vm._t("body", function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-white rounded-lg px-4 pt-5 overflow-hidden sm:items-start h-full w-full flex flex-col overflow-y-auto",
                              },
                              [
                                _vm._t("icon"),
                                _vm._t("content", function () {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm leading-5 text-gray-600",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.description) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c(
                      "section",
                      {
                        staticClass: "sm:bg-gray-100 sm:px-6 w-full px-4 py-3",
                      },
                      [
                        _vm._t("footer", function () {
                          return [
                            _c(
                              "sun-button",
                              {
                                staticClass:
                                  "custom-p-1 text-xs w-full sm:w-1/3 text-gray-700 hover:bg-gray-100 hover:text-gray-500",
                                attrs: { variant: "pill", color: "white" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("cancel")
                                  },
                                },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
        _c("div", {
          staticClass: "absolute inset-0 z-10 bg-white sm:hidden",
          attrs: { tabindex: "-1" },
        }),
        _c("div", {
          staticClass:
            "hidden absolute inset-0 z-10 bg-gray-800 opacity-50 sm:block",
          attrs: { tabindex: "-1" },
          on: {
            click: function ($event) {
              return _vm.$emit("cancel")
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }