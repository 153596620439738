<template>
  <sun-form @submit="onFormSubmit" @keypress.enter="onFormSubmit" @cancel="$emit('cancel')">
    <asterix-modal :title="title" :closable="closable" :modal-class="modalClass" @cancel="$emit('cancel')">
      <template #title>
        <slot name="title"></slot>
      </template>
      <template #container>
        <div class="w-full overflow-hidden flex flex-col flex flex-col h-full">
          <div class="rounded-lg p-4 overflow-auto flex flex-col h-full">
            <slot name="content"> </slot>
          </div>
          <div class="sm:bg-gray-100 sm:px-6 w-full px-4 py-3 h-14">
            <slot name="footer"> </slot>
          </div>
        </div>
      </template>
      <!--
      <template #content>
        <template #container>
          <div class="w-full overflow-hidden flex flex-col flex flex-col h-full">
            <div class="flex flex-col w-full mb-3 p-4">
              <slot name="content"> </slot>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="sm:bg-gray-100 sm:px-6 w-full px-4 py-3 h-14">
            <slot name="footer"> </slot>
          </div>
        </template>
      </template>
      -->
    </asterix-modal>
  </sun-form>
</template>

<script>
import AsterixModal from '@/components/organisms/shared/AsterixModal';

export default {
  name: 'AsterixFormModal',
  components: { AsterixModal },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    closable: {
      type: Boolean,
      default: () => false,
    },
    modalClass: {
      type: String,
      default: () => 'sm:max-w-lg',
    },
  },
  methods: {
    async onFormSubmit({ valid, form }) {
      this.$emit('submit', { valid, form });
    },
  },
};
</script>

<style>
.modal-content {
  max-height: 83vh;
}
</style>
