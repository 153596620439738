var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sun-form",
    {
      on: {
        submit: _vm.onFormSubmit,
        keypress: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.onFormSubmit.apply(null, arguments)
        },
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
    },
    [
      _c("asterix-modal", {
        attrs: {
          title: _vm.title,
          closable: _vm.closable,
          "modal-class": _vm.modalClass,
        },
        on: {
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [_vm._t("title")]
              },
              proxy: true,
            },
            {
              key: "container",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full overflow-hidden flex flex-col flex flex-col h-full",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "rounded-lg p-4 overflow-auto flex flex-col h-full",
                        },
                        [_vm._t("content")],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "sm:bg-gray-100 sm:px-6 w-full px-4 py-3 h-14",
                        },
                        [_vm._t("footer")],
                        2
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }