<template>
  <transition name="fade">
    <div
      v-show="open"
      class="asterix-modal max-h-full fixed z-100 w-full h-screen bottom-0 inset-x-0 px-4 py-4 sm:inset-0 flex items-center justify-center"
    >
      <div
        class="bg-white rounded-lg sm:shadow-xl transform transition-all w-full sm:w-full z-20 overflow-hidden flex flex-col sm:flex-none max-h-full"
        :class="modalClass"
      >
        <div class="flex w-full items-center justify-between px-4 py-4 sm:py-6 sm:px-6 border-b border-gray-300">
          <div class="text-gray-700 font-bold text-xl">
            <slot name="title">{{ title }}</slot>
          </div>
          <div v-if="closable" class="p-2 cursor-pointer text-gray-700 bg-gray-100 rounded" @click="$emit('cancel')">
            <close-svg class="w-4" />
          </div>
        </div>
        <!-- flex flex-col sm:flex-none flex-1 max-h-inherit -->
        <section class="flex flex-col h-full overflow-hidden">
          <slot name="container">
            <div class="w-full overflow-hidden flex flex-col h-full">
              <slot name="body">
                <div
                  class="bg-white rounded-lg px-4 pt-5 overflow-hidden sm:items-start h-full w-full flex flex-col overflow-y-auto"
                >
                  <slot name="icon" />
                  <slot name="content">
                    <p class="text-sm leading-5 text-gray-600">
                      {{ description }}
                    </p>
                  </slot>
                </div>
              </slot>
            </div>
            <section class="sm:bg-gray-100 sm:px-6 w-full px-4 py-3">
              <slot name="footer">
                <sun-button
                  variant="pill"
                  class="custom-p-1 text-xs w-full sm:w-1/3 text-gray-700 hover:bg-gray-100 hover:text-gray-500"
                  color="white"
                  @click="$emit('cancel')"
                >
                  Cancel
                </sun-button>
              </slot>
            </section>
          </slot>
        </section>
      </div>

      <div class="absolute inset-0 z-10 bg-white sm:hidden" tabindex="-1" />

      <div
        class="hidden absolute inset-0 z-10 bg-gray-800 opacity-50 sm:block"
        tabindex="-1"
        @click="$emit('cancel')"
      />
    </div>
  </transition>
</template>

<script>
import CloseSvg from '@/components/icons/CloseSvg';

export default {
  name: 'AsterixModal',
  components: { CloseSvg },
  props: {
    title: {
      type: String,
      default: () => 'Are you sure?',
    },
    description: {
      type: String,
      default: () => 'An element is going to be deleted.',
    },
    closable: {
      type: Boolean,
      default: () => false,
    },
    modalClass: {
      type: String,
      default: () => 'sm:max-w-lg',
    },
  },
  data: () => ({
    open: false,
  }),
  created() {
    setTimeout(() => (this.open = true), 100);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.max-h-90 {
  max-height: 90vh;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.max-h-inherit {
  max-height: inherit;
}
</style>
